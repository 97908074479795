export type TextFactoryProps = {
  type: 'Text';
  componentProps: {
    value: number | string;
    label?: string;
  } & React.HTMLAttributes<HTMLDivElement>;
};

export const TextFactory = ({
  componentProps: { value, label, ...props },
}: TextFactoryProps): JSX.Element => (
  <div {...props}>
    {label && (
      <span>
        <strong>{label}</strong>
      </span>
    )}
    <span>{value}</span>
  </div>
);
