import MessageMap from '../i18n.d';

const es419: MessageMap = {
  PageTitles: {
    BULK_EDITOR: 'Edición Masiva de Precios',
    VIEW_PRICES: 'Ver Precios',
    DDC_PRICE_DETAILS: 'Detalles de precio para DDC',
    PROMOTION_BULK_EDITOR: 'Edición Masiva de Promociones',
    PROMOTIONS: 'Promociones',
    NEW_PROMOTION: 'Crear nueva promoción',
  },
  ModalLabels: {
    UPLOAD_FILE: 'Subir archivo',
    CANCEL: 'Cancelar',
    FILE_SELECTION_DETAILS: 'Arrastre y suelte el archivo CSV aquí o',
    FILE_SELECTION_BUTTON: 'Seleccione Archivo',
    SEE_DETAILS: 'Ver detalless',
    HIDE_DETAILS: 'Ocultar detalles',
  },
  CsvStatusMessages: {
    UPLOAD_SUCCESS: 'El archivo se cargó con éxito',
    FILE_NOT_UPLOADED:
      'No pudimos cargar el archivo. Por favor, espere unos minutos antes de volver a intentarlo',
    ONLY_CSV_SUPPORT: 'We only support CSV files',
    INVALID_CSV_FILE: 'Solo admitimos archivos CSV',
    EMPTY_CSV_FILE: 'El archivo esta vacio',
    REQUIRED_HEADER_CSV: 'Faltan las siguientes columnas',
    DUPLICATED_HEADER_CSV: 'Las siguientes columnas están duplicadas',
    INVALID_HEADER_CSV: 'Las siguientes columnas no están permitidas',
    MIN_LINES_REQUIRED_CSV: 'El número mínimo de líneas es',
    MAX_LINES_EXCEDED_CSV: 'El número máximo de líneas es',
    REQUIRED_FIELDS_CSV: 'A las siguientes líneas les faltan algunos datos',
    INVALID_FIELD_TYPE_CSV: 'Las siguientes líneas contienen tipos de datos no válidos',
    INVALID_FIELD_VALUE_CSV: 'Las siguientes líneas contienen valores de datos no válidos',
    CONDITIONAL_REQUIRED_FIELD_CSV: 'Las siguientes líneas requieren datos específicos',
    INVALID_DATE_FORMAT: 'Las siguientes líneas contienen un formato de fecha no válido',
    DATE_SMALLER_THAN_REF:
      'Las siguientes líneas contienen fechas inferiores a una fecha de referencia',
    INVALID_DATE_VALUE: 'Las siguientes líneas requieren una fecha específica',
    FORMAT_MUST_BE: 'debe estar en formato',
    MUST_BE_GREATER_THAN: 'debe ser mayor que',
    DEPENDS_ON: 'depende de',
    EXPECTED_NUMBER_FORMAT: 'Se espera un número (ej: 12345.67)',
    CONDITIONAL_EXCLUDING_FIELD_CSV: 'Las siguientes líneas contienen datos mutuamente excluyentes',
    DOES_NOT_ALLOW_COLUMN: 'no permite llenar la columna',
    SHOULD_CONTAIN_EXPECTED_VALUES: 'debe contener uno de los siguientes',
    MUST_BE_EQUAL_OR_GREATER_THAN: 'debe ser mayor o igual que',
  },
  Table: {
    PAGE_COUNTER: 'de',
    SEARCH_PLACEHOLDER: 'Buscar',
    ROW_SELECTED: 'fila seleccionada',
    ROWS_SELECTED: 'filas seleccionadas',
    DOWNLOAD_BUTTON: 'DESCARGAR',
    LOADING_MESSAGE: 'Cargando artículos...',
    ROWS_DESELECT_ALL: 'Deseleccionar todo',
  },
  HistoryTable: {
    TITLE: 'Histórico',
    NO_UPLOADS: 'Sin cargas',
    NO_UPLOADS_DESCRIPTION: 'Los archivos cargados se mostrarán aquí',
    TEMPLATE_LINK_DESCRIPTION: 'Haga clic aquí para descargar la plantilla',
    FILE_TEMPLATE_NAME_PRICES: 'plantilla_de_precios',
    FILE_TEMPLATE_NAME_PROMOTIONS: 'plantilla_de_promociones',
    HEADER_DATE: 'Fecha',
    HEADER_USER_NAME: 'Usuario',
    HEADER_STATUS: 'Estado',
    HEADER_FILE_ROWS_QTY: 'Filas',
    HEADER_FILE_LINK: 'Descargar',
    DATA_ERROR: 'Algo salió mal al obtener el historial de carga',
    STATUS_PASS: 'Exito',
    STATUS_FAIL: 'Falla',
  },
  ViewPricesLabels: {
    DISTRIBUTION_CENTER: 'Centro de Distribución',
    SELECT_DDC: 'Seleccione',
    DDC_ERROR: 'No se puede obtener la lista de DDC',
    NO_DDC_AVAILABLE: 'No hay DDC disponible',
    HEADER_PRODUCT_TITLE: 'Título del Producto',
    HEADER_DDC: 'DDC',
    HEADER_STATUS: 'Estatus',
    STATUS_ENABLE: 'Activado',
    STATUS_DISABLE: 'Desactivado',
    HEADER_BRAND: 'Marca',
    HEADER_MANUFACTURER: 'Fabricante',
    HEADER_PRICE: 'Precio base',
    HEADER_LAST_EDITED: 'Actualizado en',
    HEADER_REGULAR_DISCOUNT: 'Descuento tradicional',
    HEADER_TIERED_DISCOUNT: 'Descuento gradual',
    NO_PRICES: 'Sin precios',
    NO_PRICES_DESCRIPTION: 'Los precios se mostrarán aquí',
    PRICES_ERROR: 'Algo salió mal al obtener los precios',
    FILTERS: {
      PRICES: 'Precio',
      WITHOUT_PRICE: 'Productos sin Precio',
      WITH_PRICE: 'Productos con Precio',
      STATUS: 'Estatus',
      ENABLED: 'Habilitado',
      DISABLED: 'Desabilitado',
      REGULAR_DISCOUNT: 'Descuento tradicional',
      TIERED_DISCOUNT: 'Descuento gradual',
      WITH_DISCOUNT: 'Productos con descuento',
      WITHOUT_DISCOUNT: 'Productos sin descuento',
    },
    DOWNLOAD_FILE_NAME: 'precios_seleccionados',
    DOWNLOAD_SUCCEEDED: 'El archivo de precios se ha descargado correctamente',
    DOWNLOAD_FAILED: 'No se puede obtener el archivo de precios',
  },
  FilterComponent: {
    CANCEL_BUTTON: 'Cancelar',
    CONFIRM_BUTTON: 'Aplicar',
    LABEL: 'Filter',
    CLEAR_ALL: 'Borrar todo',
  },
  DDCPriceDetailsLabels: {
    PRICES_ERROR: 'Algo salió mal al obtener los detalles de precio',
    SKU: 'SKU',
    VENDOR_ITEM_ID: 'VendorItemId',
    CLASS: 'Clase',
    CREATED_ON: 'Creado en',
    ATTRIBUTES: 'Atributos',
    DELIVERY_CENTER: 'Centro de Distribución',
    BASE_PRICE: 'Precio Base',
    MINIMUM_PRICE: 'Precio Mínimo',
    DEPOSIT: 'Depósito',
    TAXES: 'Impuestos',
    TAX_ID: 'ID',
    TAX_TYPE: 'Tipo',
    TAX_VALUE: 'Valor',
    PRODUCT_DETAILS_BOX_HEADER: 'Información del producto',
    PRODUCT_TITLE: 'Título del Producto',
    PRODUCT_DESCRIPTION: 'Descripción del Producto',
    STATUS: 'Estatus',
    MANUFACTURER: 'Fabricante',
    PRICE_TYPE: 'Price Type',
    MEASURE_UNIT: 'Measure Unit',
    REQUIRED_VALUE: 'Requerido!',
    REQUESTED_CHANGE:
      'La solicitud de cambio de precio fue exitosa. Por favor, espere algunos instantes hasta que el cambio esté disponible.',
    REQUESTED_CHANGE_FAIL: '¡No se puede enviar la solicitud de cambio de precio!',
    MODAL_DELETE_PRICE_TITLE: 'Eliminar precio',
    MODAL_DELETE_PRICE_DESCRIPTION:
      'Esta acción eliminará los precios y los impuestos relacionados. Quieres proceder?',
    MODAL_DELETE_PRICE_CANCEL: 'Cancelar',
    MODAL_DELETE_PRICE_CONFIRM: 'Eliminar',
    ADD_TAX: 'Añadir Impuesto',
    TAXES_EMPTY: 'Los impuestos se mostrarán aquí',
    TAX_BASE_ID: 'Tax Id Based Inclusion',
    ORDER_MINIMUM_VALUE: 'Order Sub-total Minimum Value',
    VALID_FROM: 'Valid From',
    BASE_PRICE_ERROR_MESSAGE: 'El Precio Base debe ser mayor que el Precio Mínimo',
  },
  AlertMessages: {
    COUNTRY_NOT_ALLOWED: 'País no permitido',
    PAGE_NOT_FOUND: 'Página no encontrada',
  },
  PriceAuditLogTable: {
    OLD: 'Antiguo',
    NEW: 'Nuevo',
    EMPTY_TEXT: 'No hay acciones que mostrar',
    ERROR_TEXT: 'Se ha producido un error al intentar obtener la información de registro',
    DATE: 'Fecha',
    USER: 'Nombre del Usuário',
    ACTION: 'Acción',
    HEADER: 'Información de Auditoría',
    ORIGIN: 'Origen',
    INTERFACE: 'Interfaz',
    CSV_UPLOAD: 'Carga de CSV',
    DELETE: 'Remoción',
    INSERT: 'Creación',
    UPDATE: 'Actualización',
    VIEW: 'Visualización',
  },
  TieredDiscountDialog: {
    TRIGGER: 'Mostrar',
    TITLE: 'Descuento gradual',
    DESCRIPTION: 'Descripción',
    BASE_PRICE: 'Precio base',
    DISCOUNT_RANGE: 'Rango de descuento',
    QUANTITY: 'Cantidad',
    DISCOUNT: 'Descuento',
    PERCENTAGE: 'Porcentaje',
    DISCOUNT_PRICE: 'Precio de descuento',
    ROUNDED_VALUES_TOOLTIP:
      'Los números mostrados pueden haber sido redondeados para fines de visualización, consulte los detalles del precio para obtener más información',
  },
  DiscountSection: {
    REGULAR_DISCOUNT: 'Descuento tradicional',
    TIERED_DISCOUNT: 'Descuento gradual',
    IMAGE: 'Imagen',
    TITLE: 'Título',
    DESCRIPTION: 'Descripción',
    DISCOUNT: 'Cantidad',
    PERCENTAGE: 'Porcentaje',
    FINAL_PRICE: 'Precio después del descuento',
    START_DATE: 'Fecha de inicio',
    END_DATE: 'Fecha de finalización',
    VENDOR_PROMOTION_ID: 'ID de promoción del proveedor',
    QUANTITY: 'Cantidad del producto',
    TYPE: 'Tipo',
    START_TIME: 'Hora de inicio',
    END_TIME: 'Hora de finalización',
    FINAL_PRICE_TOOLTIP: 'This final price contains only the discount amount applied.',
    ACCUMULATION: 'Accumulation type',
    PRIORITY: 'Priority',
    MINIMUM: 'De',
    MAXIMUM: 'A',
    CREATE: 'Crear descuento',
    DISCARD: 'Descartar',
    VENDOR_PROMOTION_ID_DESCRIPTION: 'Se permiten letras, números y caracteres especiales.',
    AMOUNT_OFF: 'Cantidad',
    PERCENT_OFF: 'Porcentaje',
    PROMOTIONAL_PRICE: 'Precio después del descuento',
    IMAGE_CAPTION: 'Las imágenes deben ser JPG o PNG y de menos de 1MB.',
    ADD_FIELD: 'Agregar campo',
    DISCOUNTS_EMPTY: 'Los descuentos se mostrarán aquí.',
    PAGE_COUNTER: 'de',
    ADD_DISCOUNT: 'Crear descuento',
    NOTIFICATION_SUCCESS_CREATED: '¡Descuento creado correctamente!',
    NOTIFICATION_ERROR_CREATED: 'No se puede crear el descuento',
    NOTIFICATION_SUCCESS_EDITED: '¡Descuento actualizado correctamente!',
    NOTIFICATION_ERROR_EDITED: 'No se puede editar el descuento',
    NOTIFICATION_SUCCESS_DELETED: '¡Descuento eliminado correctamente!',
    NOTIFICATION_ERROR_DELETED: 'No se puede eliminar el descuento',
    DELETE_TITLE: '¿Eliminar  este descuento?',
    DELETE_QUESTION: 'Se eliminará permanentemente.',
    DELETE_CANCEL: 'No, volver atrás',
    DELETE_CONFIRM: 'Sí, borrar',
    START_DATE_HIGHER_THAN_TODAY: 'La fecha de inicio no puede ser anterior al día actual',
    FIELD_IS_REQUIRED: 'Requerido',
    PLACEHOLDER_VENDOR_PROMOTION_ID: 'Ingrese una ID de promoción',
    PLACEHOLDER_TITLE: 'Ingrese un título de descuento',
    PLACEHOLDER_DESCRIPTION: 'Ingrese una descripción de descuento',
    MIN_FINAL_PRICE: 'El precio después del descuento no puede ser inferior al precio mínimo',
    MAX_START_DATE: 'La fecha de inicio no puede ser posterior a la fecha de finalización',
    ADD: 'Add',
    COMPOSE: 'Compose',
    UNIQUE: 'Unique',
    HIGH: 'High',
    LOW: 'Low',
    MIN_QUANTITY_ERROR: 'Debe ser una unidad mayor que la última cantidad máxima',
    MAX_QUANTITY_ERROR: 'Debe ser mayor que la cantidad mínima',
    INVALID_CHARACTER_ERROR: 'Carácter inválido detectado',
  },
  TimePicker: {
    SAVE: 'Guardar',
  },
  PromotionsListPage: {
    Table: {
      PROMO_ID: 'ID de promoción',
      TITLE: 'Título',
      DESCRIPTION: 'Descripción',
      DDC: 'CD',
      START_DATE: 'Fecha de inicio',
      END_DATE: 'Fecha de finalización',
      TYPE: 'Tipo',
      STATUS: 'Estado',
      EMPTY_STATE_MESSAGE: 'Seleccione los CD para administrar las promociones.',
    },
    FREE_GOODS: 'Mercadería gratuita',
    INTERACTIVE_COMBO: 'Interactive Combo',
    ACTIVE: 'Activo',
    INACTIVE: 'Inactive',
    ENABLED: 'Enabled',
    DISABLED: 'Deshabilitado',
    SCHEDULED: 'Agendado',
    SELECT_DDC: 'Seleccionar CD',
    MULTIPLE_DDC_SELECTED: 'CDs seleccionados',
    DDC_ERROR: 'No se pueden obtener los CDs',
    DDC_LOADING: 'Cargando CDs',
    DDC_LABEL: 'Centros de distribución',
    NEW_PROMOTION: 'Crear nueva promoción',
    DETAILS: 'Details',
    Filter: {
      TYPE: 'Tipo',
      STATUS: 'Estado',
      DATE: 'Fecha',
      START_DATE: 'Fecha de inicio',
      END_DATE: 'Fecha de finalización',
    },
  },
  PromotionTypeSection: {
    TITLE: 'Tipo de promoción',
    FREE_GOOD_TITLE: 'Mercadería gratuita',
    FREE_GOOD_DESCRIPTION:
      'Cree una condición dentro de los productos predefinidos para habilitar productos gratuitos para el PDV.',
    INTERACTIVE_COMBO_TITLE: 'Combinación interactiva',
    INTERACTIVE_COMBO_DESCRIPTION:
      'Cree un paquete de productos con cantidades variables y otorgue descuentos por la compra.',
    DIGITAL_TRADE_TITLE: 'Combinación de comercio digital (próximamente)',
    DIGITAL_TRADE_DESCRIPTION:
      'Cree un paquete de productos que se puedan canjear con puntos en el programa de recompensas.',
    FIXED_COMBO_TITLE: 'Combinación fija (próximamente)',
    FIXED_COMBO_DESCRIPTION:
      'Cree un paquete de productos con cantidades fijas y otorgue descuentos por la compra.',
    CONTINUE: 'Continuar',
  },
  FreeGoodsPage: {
    CREATE_FREE_GOOD: 'Creating new free good',
    IMAGE: 'Imagen',
    IMAGE_SIZE_ERROR: 'Image size must be less than 1MB',
    IMAGE_UPDATE_ERROR: 'Error updating image',
    IMAGE_CAPTION: 'Images must be JPG or PNG, and less than 1MB.',
    INFORMATION: 'Información',
    BUY: 'Productos que los PDV deben pedir',
    GET: 'Mercaderías gratuitas que los PDV obtendrán',
    DISTRIBUTION_CENTER: 'Centros de distribución',
    NO_DISTRIBUTION_CENTER_MESSAGE:
      'Los centros de distribución seleccionados para esta promoción aparecerán aquí.',
    NO_ITEMS_MESSAGE: 'Los productos seleccionados aparecerán aquí.',
    NO_FREE_GOODS_MESSAGE: 'Las mercaderías gratuitas seleccionadas aparecerán aquí.',
    ADD_BUY_ITEM: 'Add condition',
    ADD_GET_ITEM: 'Agregar mercaderías gratuitas',
    ADD_DISTRIBUTION_CENTER: 'Agregar CD',
    ADD_MORE_DDC: 'Add DCs to this list',
    TABLE_DESELECT_ALL: 'Deselect all',
    TABLE_ITEM_SELECTED: 'item selected',
    TABLE_ITEMS_SELECTED: 'items selected',
    TABLE_PAGE_OF: 'of',
    TABLE_SEARCH: 'Search',
    TABLE_NO_DDC_FOUND: 'No DDCs found',
    TABLE_NO_PRODUCT_FOUND: 'No products found',
    TABLE_CANCEL: 'Cancelar',
    TABLE_CONFIRM_DDC: 'Agregar CD',
    ID: 'ID',
    NAME: 'Nombre',
    STATUS: 'Estado',
    STATE: 'Estado',
    CITY: 'Ciudad',
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    CANCEL_FREE_GOOD: 'Cancelar',
    SAVE_FREE_GOOD: 'Crear mercadería gratuita',
    FREE_GOOD_CREATED: 'La mercadería gratuita se creó correctamente.',
    FREE_GOOD_UPDATED: 'La mercadería gratuita se actualizó correctamente.',
    FREE_GOOD_ERROR: 'Unable to create free good',
    FREE_GOOD_UPDATED_ERROR: 'Unable to update free good',
    FREE_GOOD_UPDATE_START_DATE_ERROR:
      'Cannot edit a free good that has already started. Change the startDate before editing.',
    MINIMUM_QUANTITY: 'La cantidad mínima debe ser',
    ONE_PRODUCT: 'Requerido para al menos un producto',
    DIVIDED_PRODUCTS: 'Dividido entre los productos',
    ADD_PRODUCTS: 'Agregar productos',
    PRODUCT: 'Producto',
    CANCEL: 'Cancelar',
    MINIMUM_QUANTITY_REQUIRED: 'Cantidad mínima requerida',
    SELECT_ITEMS: 'Seleccionar productos',
    SELECT_FREE_GOODS: 'Seleccionar mercaderías gratuitas',
    ONCE_PER_ORDER: 'Una vez por pedido',
    EVERY_TIME_MINIMUM: 'Cada vez que se alcanza la cantidad mínima de producto',
    TOTAL_QUANTITY_TOOLTIP:
      'La cantidad total se distribuirá entre los productos durante la compra.',
    FREE_GOOD_QUANTITY: 'Cantidad de mercadería gratuita',
    CONFIRM_ADD_ITEMS: 'Agregar mercaderías gratuitas',
    ADD_MORE_ITEMS_TO_LIST: 'Agregar productos a esta lista',
    FREE_GOOD_PROPORTION: 'Cuándo los PDV pueden obtener mercadería gratuita',
    ALERT_DELETE_SUCCESS: 'La mercadería gratuita se eliminó correctamente.',
    ALERT_DELETE_ERROR: 'Something went wrong. Please try again later.',
    DUPLICATED_GET_ERROR:
      '¡Las listas de Mercaderias gratuitas no deben contener exactamente los mismos productos!',
    GREATER_THAN_ZERO: 'Must be greater than zero',
  },
  DeletePromotionModal: {
    DELETE_MODAL_TITLE: '¿Eliminar promocione seleccionada?',
    DELETE_MODAL_DESCRIPTION: 'La promoción seleccionada se eliminará permanentemente.',
    DELETE_MODAL_CANCEL: 'Volver atrás',
    DELETE_MODAL_CONFIRM: 'Eliminar',
    DELETE_BUTTON_LABEL: 'Eliminar promoción',
  },
  LoadingModal: {
    LOADING_TITLE: 'Cargando...',
  },
  DDCSection: {
    DDC_TITLE: 'Centros de distribución',
    DDC_DESCRIPTION: 'Agregue CD elegibles en los que esta promoción estará disponible.',
    ADD_DDC: 'Agregar CDs',
    ADD_MORE_DDC: 'Agregar CD a esta lista',
    ID: 'ID',
    NAME: 'Nombre',
    STATUS: 'Status',
    STATE: 'Estado',
    CITY: 'Ciudade',
    ACTIVE: 'Activo',
    INACTIVE: 'Inactivo',
    NO_AVAILABLE_DDC: 'No se encontraron DC',
    NO_SELECTED_DDC: 'Aún no hay CD elegibles. Comience a agregarlos.',
    CONFIRM: 'Agregar CDs',
    CANCEL: 'Cancelar',
    SINGLE_DDC_SELECTED: 'DC selected',
    MULTIPLE_DDC_SELECTED: 'DCs selected',
  },
  PromotionSectionTable: {
    DESELECT_ALL: 'Deseleccionar todo',
    ITEM_SELECTED: 'elemento seleccionado',
    ITEMS_SELECTED: 'elementos seleccionados',
    PAGE_OF: 'de',
    SEARCH: 'Buscar',
  },
  InteractiveComboSection: {
    CREATE_INTERACTIVE_COMBO: 'Crear nueva promoción',
    IMAGE_TITLE: 'Imagen',
    INFORMATION_TITLE: 'Información',
    BUY_TITLE: 'Productos requeridos para el descuento',
    BUY_DESCRIPTION:
      'Agregue los productos que el PDV necesita pedir para obtener productos con descuento.',
    GET_TITLE: 'Productos con descuento',
    GET_DESCRIPTION: 'Agregue productos con descuento que estarán disponibles para el PDV.',
    DDC_TITLE: 'Centros de distribución',
    DDC_DESCRIPTION: 'Add eligible DCs in which this promotion will be available.',
    CANCEL_COMBO: 'Cancelar',
    CREATE_COMBO: 'Cree una promoción',
    CREATE_TOAST_SUCCESS: 'La promoción se creó correctamente.',
    CREATE_TOAST_ERROR: 'Error creating interactive combo',
    UPDATE_TOAST_SUCCESS: 'La promoción se actualizó correctamente.',
    UPDATE_TOAST_ERROR: 'Error updating interactive combo',
    ALERT_DELETE_SUCCESS: 'Interactive combo successfully deleted.',
    ALERT_DELETE_ERROR: 'Algo salió mal. Por favor, inténtelo de nuevo más tarde.',
    DUPLICATED_TOAST_ERROR:
      'Las listas de condiciones para combos interactivos no pueden contener exactamente los mismos productos.',
  },
  BuySection: {
    MODAL_TITLE: 'Seleccionar productos',
    CANCEL: 'Cancelar',
    CONFIRM: 'Agregue productos',
    QUANTITY_TOOLTIP:
      'La cantidad mínima establecida se dividirá entre los productos seleccionados de acuerdo con la preferencia del PDV.',
    QUANTITY: 'Cantidad mínima requerida',
    ITEMS: 'Items',
    NO_SELECTED_ITEMS: 'Aún no hay productos requeridos. Comience a agregarlos.',
    NO_AVAILABLE_ITEMS: 'No products found',
    ADD_ITEMS: 'Agregue productos',
    ADD_MORE_ITEMS: 'Agregue productos a esta lista',
    PRODUCT: 'Producto',
  },
  GetSection: {
    MODAL_TITLE: 'Seleccionar productos',
    CANCEL: 'Cancel',
    CONFIRM: 'Agregue productos',
    ITEMS: 'Items',
    NO_SELECTED_ITEMS: 'Aún no hay productos con descuento. Comience a agregarlos.',
    NO_AVAILABLE_ITEMS: 'No products found',
    ADD_ITEMS: 'Agregue productos',
    ADD_MORE_ITEMS: 'Agregue productos a esta lista',
    PRODUCT: 'Producto',
    PERCENTAGE: 'Porcentaje de descuento',
    MAX_QUANTITY: 'Cantidad máxima',
  },
  PromotionValidation: {
    UPDATE_START_DATE_ERROR:
      'Cannot edit a promotion that has already started. Change the startDate before editing.',
  },
  ImageCommon: {
    CAPTION: 'Format: JPG or PNG. Size: 1MB max.',
    SIZE_LIMIT_ERROR: 'Image size must be less than 1MB',
    UPDATE_ERROR: 'Error updating image',
  },
  InformationSectionCommon: {
    VENDOR_PROMOTION_ID: 'Vendor promotion ID',
    TITLE: 'Título',
    DESCRIPTION: 'Descripción',
    START_DATE: 'Fecha de inicio',
    END_DATE: 'Fecha de finalización',
    START_TIME: 'Hora de inicio',
    END_TIME: 'Hora de finalización',
    TITLE_PLACEHOLDER: 'Ingrese el título de la promoción',
    DESCRIPTION_PLACEHOLDER: 'Ingrese la descripción de la promoción',
    DATE_TIME_PLACEHOLDER: 'Seleccionar',
    REQUIRED_FIELD: 'Required',
    GREATER_THAN_TODAY: 'La fecha de inicio debe ser posterior o igual a hoy.',
    GREATER_THAN_START_DATE: 'La hora de inicio debe ser posterior o igual a la hora actual.',
  },
  PromotionDeleteItems: {
    TITLE: 'Delete this list of products?',
    DESCRIPTION: 'This list of products will be deleted permanently.',
    CANCEL: 'Go back',
    CONFIRM: 'Delete',
  },
};

export default es419;
