import { CustomFactory, CustomFactoryProps } from './CustomFactory';
import { InputFactory, InputFactoryProps } from './InputFactory';
import { SelectFactory, SelectFactoryProps } from './SelectFactory';
import { TextButtonFactory, TextButtonFactoryProps } from './TextButtonFactory';
import { TextFactory, TextFactoryProps } from './TextFactory';

type EmptyFactoryProps = {
  type: 'Empty';
};

export type FieldsFactoryProps =
  | SelectFactoryProps
  | InputFactoryProps
  | TextFactoryProps
  | TextButtonFactoryProps
  | CustomFactoryProps
  | EmptyFactoryProps;

export type FieldsWithGridFactoryPros = {
  gridArea: string;
} & FieldsFactoryProps;

export const FieldsFactory = (fields: FieldsFactoryProps): JSX.Element => {
  const { type } = fields;

  switch (type) {
    case 'Select':
      return SelectFactory(fields);
    case 'Input':
      return InputFactory(fields);
    case 'Text':
      return TextFactory(fields);
    case 'TextButton':
      return TextButtonFactory(fields);
    case 'Custom':
      return CustomFactory(fields);
    default:
      return <div></div>;
  }
};
