import { useAnalyticsService } from 'admin-portal-shared-services';
import { useEffect } from 'react';

interface IAnalytics {
  key: string;
}

export const useAnalytics = ({ key }: IAnalytics): void => {
  const analyticsService = useAnalyticsService();

  useEffect(() => {
    analyticsService.load(key);
  }, [analyticsService, key]);
};

export default useAnalytics;
