import { useAuthenticationService, useFeatureToggleServiceV2 } from 'admin-portal-shared-services';
import { useEffect, useState } from 'react';
import { projectName } from '../constants/featureToggle';

const useFeatureToggleWithAttributes = (
  flagName: string,
  attributes: Record<string, any>
): [boolean, boolean] => {
  const userId = useAuthenticationService().getUserId();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [value, setValue] = useState<boolean>(false);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useFeatureToggleServiceV2(projectName)
      .onReady()
      .then(() => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useFeatureToggleServiceV2(projectName)
          .isFeatureEnabledAudiences(flagName, userId, attributes)
          .then((res) => {
            setValue(res);
            setIsLoading(false);
          });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributes]);

  return [value, isLoading];
};

export default useFeatureToggleWithAttributes;
