export const projectName = 'Price Admin';
export const upComingPricesFlag = 'upcoming_prices';
export const promotionsBulkEditorFlag = 'enable_promotions_bulk_editor';
export const editSinglePriceFlag = 'enable_edit_single_price';
export const priceSelectionCsvExportFlag = 'price_selection_csv_export';
export const auditLogFlag = 'auditlog';
export const regularDiscountFlag = 'regular_discount';
export const tieredDiscountFlag = 'tiered_discount';
export const promotionCountryEnabledFlag = 'promotions_enabled_countries';
export const accumulationAndPriorityFlag = 'deals_accumulation_and_priority';
export const promotionsListPageFlag = 'list_promotions_page';
export const newPromotionsPageFlag = 'new_promotion_page';
export const promotionsFreeGoodPageFlag = 'promotion_freegood_page';
export const interactiveComboFlag = 'enable_interactive_combos';
