import { Select, SelectProps } from '@hexa-ui/components';
import { SelectOptionProps } from '@hexa-ui/components/dist/declarations/src/lib/select/components/SelectOption/SelectOption';

type SelectHexaProps = SelectProps &
  React.RefAttributes<HTMLButtonElement> & {
    'data-testid'?: string;
  };

type OptionsProps = Omit<
  SelectOptionProps & { label: string; key: string; 'data-testid'?: string },
  'children'
>[] & {
  'data-testid'?: string;
};

export type SelectFactoryProps = {
  type: 'Select';
  componentProps: SelectHexaProps;
  optionsProps: OptionsProps;
};

export const SelectFactory = (props: SelectFactoryProps): JSX.Element => {
  const { optionsProps, componentProps } = props;
  return (
    <Select.Root {...componentProps}>
      {optionsProps.map(({ label, key, ...optProps }) => (
        <Select.Option key={key} {...optProps}>
          {label}
        </Select.Option>
      ))}
    </Select.Root>
  );
};
