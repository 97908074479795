import Grid from '@material-ui/core/Grid';

import FieldsFactory, { FieldsFactoryProps } from '@/utils/FieldsFactory';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { makeStyles } from '@material-ui/core';
import { BreadCrumbConfig, setAppHeaderConfig } from 'admin-portal-shared-services';
import { useMemo } from 'react';

const usePageStyles = makeStyles({
  pageContainer: {
    paddingBottom: 24,
    fontFamily: 'Barlow, "San Francisco", Roboto, "Segoe UI", Helvetica, sans-serif',
  },
  pageHeading: {
    paddingTop: 24,
    paddingBottom: 24,
  },
  withoutTitle: {
    position: 'relative',
    top: '-5rem',
  },
});

interface PageProps {
  title?: string;
  id: string;
  children: ReactJSXElement;
  displayAppHeaderSelectors?: boolean;
  breadcrumbConfig?: BreadCrumbConfig;
  optionalComponents?: FieldsFactoryProps[];
}

export const Page = ({
  displayAppHeaderSelectors,
  title,
  id,
  breadcrumbConfig,
  optionalComponents,
  children,
}: PageProps): JSX.Element => {
  const classes = usePageStyles();

  const shouldHideSelectors = () => displayAppHeaderSelectors === false;

  useMemo(() => {
    if (shouldHideSelectors()) {
      setAppHeaderConfig({
        countrySelect: false,
        vendorSelect: false,
        pageTitle: title ?? ' ',
        breadcrumbConfig,
      });
    } else {
      setAppHeaderConfig({
        countrySelect: true,
        vendorSelect: true,
        pageTitle: title ?? ' ',
        breadcrumbConfig,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, displayAppHeaderSelectors]);

  return (
    <Grid
      container
      className={`${classes.pageContainer} ${title ? '' : classes.withoutTitle}`}
      data-testid={id}
    >
      <Grid item xs={12}>
        <div
          id="optional-selectors"
          style={{
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'end',
          }}
        >
          {optionalComponents?.map((selector, index) => (
            <div
              key={index}
              style={{
                display: 'inline-block',
                verticalAlign: 'bottom',
                marginLeft: 'var(--space-4)',
                marginBottom: 'var(--space-4)',
              }}
            >
              <FieldsFactory {...selector} />
            </div>
          ))}
        </div>
        {children}
      </Grid>
    </Grid>
  );
};
