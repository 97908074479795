import DesignTokensProvider from '@admin-portal-shared-components/design-tokens-provider';
import { createGenerateClassName, StylesProvider } from '@material-ui/core';
import { createFeatureToggleServiceV2 } from 'admin-portal-shared-services';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { AppbarConfig, EnvConfig, EnvProvider } from './components';
import { projectName } from './constants/featureToggle';
import { IntlProvider } from './i18n';
import Router from './Routes';
import useAnalytics from './hooks/useAnalytics/useAnalytics';

export default function App(props: EnvConfig): JSX.Element {
  createFeatureToggleServiceV2(projectName, props.OPTIMIZELY_KEY);
  process.env.EUROPE_COUNTRIES = props.EUROPE_COUNTRIES;

  useAnalytics({
    key: props.SEGMENT_KEY,
  });

  return (
    <EnvProvider env={props}>
      <StylesProvider
        generateClassName={createGenerateClassName({
          productionPrefix: 'bees-hub-price-admin-mfe',
          seed: 'bees-hub-price-admin-mfe',
        })}
      >
        <IntlProvider>
          <AppbarConfig>
            <BrowserRouter>
              <DesignTokensProvider>
                <Router />
              </DesignTokensProvider>
            </BrowserRouter>
          </AppbarConfig>
        </IntlProvider>
      </StylesProvider>
    </EnvProvider>
  );
}
