import { LoadingBuzz } from '@hexa-ui/components';
import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

const BulkEditorPage = lazy(() => import('./pages/BulkEditorPage/BulkEditorPage'));
const ViewPricesPage = lazy(() => import('./pages/ViewPricesPage/ViewPricesPage'));
const PriceDetailsPage = lazy(() => import('./pages/DDCPriceDetailsPage'));
const PromotionBulkEditorPage = lazy(
  () => import('./pages/PromotionBulkEditorPage/PromotionBulkEditorPage')
);
const PromotionsPage = lazy(() => import('./pages/PromotionsPage'));
const CreatePromotionPage = lazy(() => import('./pages/NewPromotionPage'));
const PromotionFreeGoodPage = lazy(() => import('./pages/PromotionFreeGoodPage'));
const PromotionInteractiveComboPage = lazy(() => import('./pages/PromotionInteractiveComboPage'));

const Router = (): JSX.Element => {
  return (
    <Suspense
      fallback={
        <div style={{ padding: '100px' }}>
          <LoadingBuzz />
        </div>
      }
    >
      <Routes>
        <Route path="/price-admin" element={<Navigate replace to="/price-admin/view-prices" />} />
        <Route path="/price-admin/price-bulk-editor" element={<BulkEditorPage />} />
        <Route path="/price-admin/promotion-bulk-editor" element={<PromotionBulkEditorPage />} />
        <Route path="/price-admin/view-prices" element={<ViewPricesPage />} />
        <Route path="/price-admin/promotions" element={<PromotionsPage />} />
        <Route path="/price-admin/promotions/create" element={<CreatePromotionPage />} />
        <Route path="/price-admin/promotions/free-good" element={<PromotionFreeGoodPage />} />
        <Route
          path="/price-admin/promotions/interactive-combos"
          element={<PromotionInteractiveComboPage />}
        />
        <Route
          path="/price-admin/ddc-price-details/:ddcId/:vendorItemId"
          element={<PriceDetailsPage />}
        />
        <Route path="/price-admin/*" element={<Navigate replace to="/error/404" />} />
      </Routes>
    </Suspense>
  );
};
export default Router;
