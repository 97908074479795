import MessageMap from '../i18n.d';

const ptBR: MessageMap = {
  PageTitles: {
    BULK_EDITOR: 'Edição de Preços em Grupo',
    VIEW_PRICES: 'Visualizar Preços',
    DDC_PRICE_DETAILS: 'Detalhes de preço para DDC',
    PROMOTION_BULK_EDITOR: 'Edição de Promoções em Grupo',
    PROMOTIONS: 'Promoções',
    NEW_PROMOTION: 'Criar nova promoção',
    DETAILS: 'Detalhes',
  },
  ModalLabels: {
    UPLOAD_FILE: 'Enviar arquivo',
    CANCEL: 'Cancelar',
    FILE_SELECTION_DETAILS: 'Arraste e solte o arquivo CSV aqui ou',
    FILE_SELECTION_BUTTON: 'Selecione arquivo',
    SEE_DETAILS: 'Ver detalhes',
    HIDE_DETAILS: 'Ocultar detalhes',
  },
  FilterComponent: {
    CANCEL_BUTTON: 'Cancelar',
    CONFIRM_BUTTON: 'Aplicar',
    LABEL: 'Filtro',
    CLEAR_ALL: 'Limpar tudo',
  },
  CsvStatusMessages: {
    UPLOAD_SUCCESS: 'O arquivo foi enviado com sucesso',
    FILE_NOT_UPLOADED:
      'Não conseguimos enviar o arquivo. Por favor, aguarde alguns minutos antes de tentar novamente',
    ONLY_CSV_SUPPORT: 'Nós suportamos apenas arquivos CSV',
    INVALID_CSV_FILE: 'Encontramos alguns problemas no arquivo',
    EMPTY_CSV_FILE: 'O arquivo está vazio',
    REQUIRED_HEADER_CSV: 'As colunas a seguir estão faltando',
    DUPLICATED_HEADER_CSV: 'As colunas a seguir estão duplicadas',
    INVALID_HEADER_CSV: 'As colunas a seguir não são permitidas',
    MIN_LINES_REQUIRED_CSV: 'O número mínimo de linhas é',
    MAX_LINES_EXCEDED_CSV: 'O número máximo de linhas é',
    REQUIRED_FIELDS_CSV: 'Nas linhas a seguir estão faltando alguns dados',
    INVALID_FIELD_TYPE_CSV: 'As linhas a seguir contêm tipos de dados inválidos',
    INVALID_FIELD_VALUE_CSV: 'As linhas a seguir contêm valores inválidos',
    CONDITIONAL_REQUIRED_FIELD_CSV: 'As linhas a seguir precisam de alguns dados específicos',
    INVALID_DATE_FORMAT: 'As linhas a seguir contêm formato de data inválido',
    DATE_SMALLER_THAN_REF: 'As linhas a seguir contêm datas inferiores uma data de referência',
    INVALID_DATE_VALUE: 'As linhas a seguir exigem data específica',
    FORMAT_MUST_BE: 'deve ser no formato',
    MUST_BE_GREATER_THAN: 'deve ser maior que',
    DEPENDS_ON: 'depende de',
    EXPECTED_NUMBER_FORMAT: 'Um número é esperado (ex: 12345.67)',
    CONDITIONAL_EXCLUDING_FIELD_CSV: 'As linhas a seguir contêm dados mutuamente exclusivos',
    DOES_NOT_ALLOW_COLUMN: 'não permite o preenchimento da coluna',
    SHOULD_CONTAIN_EXPECTED_VALUES: 'deve conter um dos seguintes',
    MUST_BE_EQUAL_OR_GREATER_THAN: 'deve ser maior ou igual a',
  },
  Table: {
    PAGE_COUNTER: 'de',
    SEARCH_PLACEHOLDER: 'Procurar',
    ROW_SELECTED: 'linha selecionada',
    ROWS_SELECTED: 'linhas selecionadas',
    DOWNLOAD_BUTTON: 'BAIXAR',
    LOADING_MESSAGE: 'Carregando os items...',
    ROWS_DESELECT_ALL: 'Desmarcar tudo',
  },
  HistoryTable: {
    TITLE: 'Histórico',
    NO_UPLOADS: 'Nenhum envio',
    NO_UPLOADS_DESCRIPTION: 'Os arquivos enviados serão exibidos aqui',
    TEMPLATE_LINK_DESCRIPTION: 'Clique aqui para baixar o modelo',
    FILE_TEMPLATE_NAME_PRICES: 'modelo_de_precos',
    FILE_TEMPLATE_NAME_PROMOTIONS: 'modelo_de_promocoes',
    HEADER_DATE: 'Data',
    HEADER_USER_NAME: 'Usuário',
    HEADER_STATUS: 'Situação',
    HEADER_FILE_ROWS_QTY: 'Linhas',
    HEADER_FILE_LINK: 'Baixar',
    DATA_ERROR: 'Algo deu errado ao obter o histórico de uploads',
    STATUS_PASS: 'Sucesso',
    STATUS_FAIL: 'Falha',
  },
  ViewPricesLabels: {
    DISTRIBUTION_CENTER: 'Centro de Distribuição',
    SELECT_DDC: 'Selecione',
    DDC_ERROR: 'Não foi possivel obter a lista de DDC',
    NO_DDC_AVAILABLE: 'Nenhum DDC disponível',
    HEADER_PRODUCT_TITLE: 'Título do Produto',
    HEADER_DDC: 'DDC',
    HEADER_STATUS: 'Estado',
    STATUS_ENABLE: 'Habilitado',
    STATUS_DISABLE: 'Desabilitado',
    HEADER_BRAND: 'Marca',
    HEADER_MANUFACTURER: 'Fabricante',
    HEADER_PRICE: 'Preço base',
    HEADER_LAST_EDITED: 'Atualizado em',
    HEADER_REGULAR_DISCOUNT: 'Desconto normal',
    HEADER_TIERED_DISCOUNT: 'Desconto gradual',
    NO_PRICES: 'Nenhum preço',
    NO_PRICES_DESCRIPTION: 'Os preços serão exibidos aqui',
    PRICES_ERROR: 'Algo deu errado ao obter os preços',
    FILTERS: {
      PRICES: 'Preço',
      WITHOUT_PRICE: 'Produtos sem Preço',
      WITH_PRICE: 'Produtos com Preço',
      STATUS: 'Status',
      ENABLED: 'Habilitado',
      DISABLED: 'Desabilitado',
      REGULAR_DISCOUNT: 'Desconto normal',
      TIERED_DISCOUNT: 'Desconto gradual',
      WITH_DISCOUNT: 'Produtos com desconto',
      WITHOUT_DISCOUNT: 'Produtos sem desconto',
    },
    DOWNLOAD_FILE_NAME: 'precos_selecionados',
    DOWNLOAD_SUCCEEDED: 'O arquivo de preços foi baixado com sucesso',
    DOWNLOAD_FAILED: 'Não foi possível obter o arquivo de preços',
  },
  DDCPriceDetailsLabels: {
    PRICES_ERROR: 'Algo deu errado ao obter os detalhes de preço',
    SKU: 'SKU',
    VENDOR_ITEM_ID: 'VendorItemId',
    CLASS: 'Classe',
    CREATED_ON: 'Criado Em',
    ATTRIBUTES: 'Atributos',
    DELIVERY_CENTER: 'Centro de Distribuição',
    BASE_PRICE: 'Preço Base',
    MINIMUM_PRICE: 'Preço Mínimo',
    DEPOSIT: 'Depósito',
    TAXES: 'Impostos',
    TAX_ID: 'ID',
    TAX_TYPE: 'Tipo',
    TAX_VALUE: 'Valor',
    PRODUCT_DETAILS_BOX_HEADER: 'Informação do Produto',
    PRODUCT_TITLE: 'Título do Produto',
    PRODUCT_DESCRIPTION: 'Descrição do Produto',
    STATUS: 'Estado',
    PRICE_TYPE: 'Price Type',
    MEASURE_UNIT: 'Measure Unit',
    REQUIRED_VALUE: 'Obrigatório!',
    REQUESTED_CHANGE:
      'A solicitação de mudança de preço foi bem-sucedida. Por favor, aguarde alguns instantes até que a mudança esteja disponível.',
    REQUESTED_CHANGE_FAIL: 'Não foi possível enviar solicitação de mudança de preço!',
    MODAL_DELETE_PRICE_TITLE: 'Excluir preço',
    MODAL_DELETE_PRICE_DESCRIPTION:
      'Esta ação excluirá preços e impostos relacionados. Deseja continuar?',
    MODAL_DELETE_PRICE_CANCEL: 'Cancelar',
    MODAL_DELETE_PRICE_CONFIRM: 'Excluir',
    ADD_TAX: 'Adicionar Imposto',
    TAXES_EMPTY: 'Os impostos serão mostrados aqui.',
    TAX_BASE_ID: 'Tax Id Based Inclusion',
    ORDER_MINIMUM_VALUE: 'Order Sub-total Minimum Value',
    VALID_FROM: 'Valid From',
    BASE_PRICE_ERROR_MESSAGE: 'Preço Base deve ser maior que o Preço Mínimo',
  },
  AlertMessages: {
    COUNTRY_NOT_ALLOWED: 'País não permitido',
    PAGE_NOT_FOUND: 'Página não encontrada',
  },
  PriceAuditLogTable: {
    OLD: 'Antigo',
    NEW: 'Novo',
    EMPTY_TEXT: 'Não há ações para mostrar',
    ERROR_TEXT: 'Ocorreu um erro enquanto tentávamos trazer os dados de registro.',
    LOADING_MESSAGE: 'Carregando os items',
    DATE: 'Data',
    USER: 'Nome do Usuário',
    ACTION: 'Ação',
    HEADER: 'Informação de Auditoria',
    ORIGIN: 'Origem',
    INTERFACE: 'Interface',
    CSV_UPLOAD: 'Upload de CSV',
    DELETE: 'Remoção',
    INSERT: 'Criação',
    UPDATE: 'Atualização',
    VIEW: 'Visualização',
  },
  TieredDiscountDialog: {
    TRIGGER: 'Mostrar',
    TITLE: 'Desconto gradual',
    DESCRIPTION: 'Descrição',
    BASE_PRICE: 'Preço base',
    DISCOUNT_RANGE: 'Faixa de desconto',
    QUANTITY: 'Quantidade',
    DISCOUNT: 'Desconto',
    PERCENTAGE: 'Porcentagem',
    DISCOUNT_PRICE: 'Preço com desconto',
    ROUNDED_VALUES_TOOLTIP:
      'Os números exibidos podem ter sido arredondados para fins de exibição, consulte os detalhes do preço para obter mais informações',
  },
  DiscountSection: {
    REGULAR_DISCOUNT: 'Desconto normal',
    TIERED_DISCOUNT: 'Desconto gradual',
    IMAGE: 'Imagem',
    TITLE: 'Título',
    DESCRIPTION: 'Descrição',
    DISCOUNT: 'Quantia',
    PERCENTAGE: 'Porcentagem',
    FINAL_PRICE: 'Preço após desconto',
    START_DATE: 'Data de início',
    END_DATE: 'Data final',
    VENDOR_PROMOTION_ID: 'ID da promoção do fornecedor',
    QUANTITY: 'Quantidade de produtos',
    TYPE: 'Tipo',
    START_TIME: 'Hora de início',
    END_TIME: 'Hora de término',
    FINAL_PRICE_TOOLTIP: 'This final price contains only the discount amount applied.',
    ACCUMULATION: 'Accumulation type',
    PRIORITY: 'Priority',
    MINIMUM: 'De',
    MAXIMUM: 'Para',
    CREATE: 'Criar desconto',
    DISCARD: 'Descartar',
    VENDOR_PROMOTION_ID_DESCRIPTION: 'Letras, números e caracteres especiais são permitidos.',
    AMOUNT_OFF: 'Quantia',
    PERCENT_OFF: 'Porcentagem',
    PROMOTIONAL_PRICE: 'Preço após desconto',
    IMAGE_CAPTION: 'As imagens devem ser JPG ou PNG e ter menos de 1MB.',
    ADD_FIELD: 'Adicionar campo',
    DISCOUNTS_EMPTY: 'Os descontos serão mostrados aqui.',
    PAGE_COUNTER: 'de',
    ADD_DISCOUNT: 'Criar desconto',
    NOTIFICATION_SUCCESS_CREATED: 'Desconto criado com sucesso!',
    NOTIFICATION_ERROR_CREATED: 'Não foi possível criar o desconto',
    NOTIFICATION_SUCCESS_EDITED: 'Desconto atualizado com sucesso!',
    NOTIFICATION_ERROR_EDITED: 'Não foi possível editar o desconto',
    NOTIFICATION_SUCCESS_DELETED: 'Desconto deletado com sucesso!',
    NOTIFICATION_ERROR_DELETED: 'Não foi possível deletar o desconto',
    DELETE_TITLE: 'Deletar este desconto?',
    DELETE_QUESTION: 'Ele será deletado permanentemente.',
    DELETE_CANCEL: 'Não, voltar',
    DELETE_CONFIRM: 'Sim, deletar',
    START_DATE_HIGHER_THAN_TODAY: 'A data de início não pode ser menor do que a data atual',
    FIELD_IS_REQUIRED: 'Obrigatório',
    PLACEHOLDER_VENDOR_PROMOTION_ID: 'Insira uma ID de promoção',
    PLACEHOLDER_TITLE: 'Insira um título de desconto',
    PLACEHOLDER_DESCRIPTION: 'Insira uma descrição de desconto',
    MIN_FINAL_PRICE: 'O valor após o desconto não pode ser menor do que o preço mínimo',
    MAX_START_DATE: 'A data de início não pode ser maior do que a data de término',
    ADD: 'Add',
    COMPOSE: 'Compose',
    UNIQUE: 'Unique',
    HIGH: 'High',
    LOW: 'Low',
    MIN_QUANTITY_ERROR: 'Deve ser uma unidade maior que a última quantidade máxima',
    MAX_QUANTITY_ERROR: 'Deve ser maior que a quantidade mínima',
    INVALID_CHARACTER_ERROR: 'Caractere inválido detectado',
  },
  TimePicker: {
    SAVE: 'Salvar',
  },
  PromotionsListPage: {
    Table: {
      PROMO_ID: 'Código da promoção',
      TITLE: 'Título',
      DESCRIPTION: 'Descrição',
      DDC: 'CD',
      START_DATE: 'Data de início',
      END_DATE: 'Data de término',
      TYPE: 'Tipo',
      STATUS: 'Status',
      EMPTY_STATE_MESSAGE: 'Selecione os CDs para gerenciar promoções.',
    },
    FREE_GOODS: 'Mercadoria gratuita',
    INTERACTIVE_COMBO: 'Interactive Combo',
    ACTIVE: 'Ativo(a)',
    INACTIVE: 'Inactive',
    ENABLED: 'Enabled',
    DISABLED: 'Desabilitado',
    SCHEDULED: 'Agendado',
    SELECT_DDC: 'Selecione um CD',
    MULTIPLE_DDC_SELECTED: 'CDs selecionados',
    DDC_ERROR: 'Erro ao buscar CD',
    DDC_LOADING: 'Carregando CDs',
    DDC_LABEL: 'Centros de distribuição',
    NEW_PROMOTION: 'Criar nova promoção',
    Filter: {
      TYPE: 'Tipo',
      STATUS: 'Status',
      DATE: 'Data',
      START_DATE: 'Data de início',
      END_DATE: 'Data de término',
    },
  },
  PromotionTypeSection: {
    TITLE: 'Tipo de promoção',
    FREE_GOOD_TITLE: 'Mercadoria gratuita',
    FREE_GOOD_DESCRIPTION:
      'Crie uma condição dentro de produtos predefinidos para habilitar produtos gratuitos para o PDV.',
    INTERACTIVE_COMBO_TITLE: 'Combo interativo',
    INTERACTIVE_COMBO_DESCRIPTION:
      'Crie um pacote de produtos com quantidades variáveis e conceda descontos para a compra.',
    DIGITAL_TRADE_TITLE: 'Combo de comércio digital (em breve)',
    DIGITAL_TRADE_DESCRIPTION:
      'Crie um pacote de produtos que pode ser resgatado com pontos no programa de recompensas.',
    FIXED_COMBO_TITLE: 'Combo fixo (em breve)',
    FIXED_COMBO_DESCRIPTION:
      'Crie um pacote de produtos com quantidades fixas e conceda descontos para a compra.',
    CONTINUE: 'Continuar',
  },
  FreeGoodsPage: {
    CREATE_FREE_GOOD: 'Criar nova promoção',
    IMAGE: 'Imagem',
    IMAGE_SIZE_ERROR: 'Image size must be less than 1MB',
    IMAGE_UPDATE_ERROR: 'Error updating image',
    IMAGE_CAPTION: 'Images must be JPG or PNG, and less than 1MB.',
    INFORMATION: 'Informações',
    BUY: 'Produtos que os PDVs precisam encomendar',
    GET: 'Mercadorias gratuitas que os PDVs receberão',
    DISTRIBUTION_CENTER: 'Centros de distribuição',
    NO_DISTRIBUTION_CENTER_MESSAGE:
      'Os centros de distribuição selecionados para esta promoção aparecerão aqui.',
    NO_ITEMS_MESSAGE: 'Os produtos selecionados aparecerão aqui.',
    NO_FREE_GOODS_MESSAGE: 'As mercadorias gratuitas selecionadas aparecerão aqui.',
    ADD_BUY_ITEM: 'Add condition',
    ADD_GET_ITEM: 'Adicionar mercadorias gratuitas',
    ADD_DISTRIBUTION_CENTER: 'Adicionar CDs',
    ADD_MORE_DDC: 'Add DCs to this list',
    TABLE_DESELECT_ALL: 'Deselect all',
    TABLE_ITEM_SELECTED: 'item selected',
    TABLE_ITEMS_SELECTED: 'items selected',
    TABLE_PAGE_OF: 'de',
    TABLE_SEARCH: 'Pesquisar',
    TABLE_NO_DDC_FOUND: 'No DDCs found',
    TABLE_NO_PRODUCT_FOUND: 'No products found',
    TABLE_CANCEL: 'Cancelar',
    TABLE_CONFIRM_DDC: 'Adicionar CDs',
    ID: 'ID',
    NAME: '​Nome',
    STATUS: 'Status',
    STATE: 'Estado',
    CITY: 'Cidade',
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    CANCEL_FREE_GOOD: 'Cancelar',
    SAVE_FREE_GOOD: 'Criar mercadoria gratuita',
    FREE_GOOD_CREATED: 'Mercadoria gratuita criada com sucesso.',
    FREE_GOOD_UPDATED: 'Mercadoria gratuita atualizada com sucesso.',
    FREE_GOOD_ERROR: 'Unable to create free good',
    FREE_GOOD_UPDATED_ERROR: 'Unable to update free good',
    FREE_GOOD_UPDATE_START_DATE_ERROR:
      'Cannot edit a free good that has already started. Change the startDate before editing.',
    MINIMUM_QUANTITY: 'A quantidade mínima deve ser',
    ONE_PRODUCT: 'Necessário para pelo menos um produto',
    DIVIDED_PRODUCTS: 'Dividido entre os produtos',
    ADD_PRODUCTS: 'Adicione produtos',
    PRODUCT: 'Produto',
    CANCEL: 'Cancelar',
    MINIMUM_QUANTITY_REQUIRED: 'Quantidade mínima necessária',
    SELECT_ITEMS: 'Selecionar produtos',
    SELECT_FREE_GOODS: 'Selecionar mercadorias gratuitas',
    ONCE_PER_ORDER: 'Uma vez por pedido',
    EVERY_TIME_MINIMUM: 'Sempre que a quantidade mínima do produto for atendida',
    TOTAL_QUANTITY_TOOLTIP:
      'A quantidade total será distribuída entre os produtos durante a compra.',
    FREE_GOOD_QUANTITY: 'Quantidade de mercadorias gratuitas',
    CONFIRM_ADD_ITEMS: 'Adicionar mercadorias gratuitas',
    ADD_MORE_ITEMS_TO_LIST: 'Adicione produtos a esta lista',
    FREE_GOOD_PROPORTION: 'Quando PDVs podem obter mercadorias gratuitas',
    ALERT_DELETE_SUCCESS: 'Mercadoria gratuita excluída com sucesso.',
    ALERT_DELETE_ERROR: 'Something went wrong. Please try again later.',
    DUPLICATED_GET_ERROR:
      'As listas de Mercadorias gratuitas não devem conter exatamente os mesmos produtos!',
  },
  GREATER_THAN_ZERO: 'Must be greater than zero',
  DeletePromotionModal: {
    DELETE_MODAL_TITLE: 'Deseja excluir essa promoção?',
    DELETE_MODAL_DESCRIPTION: 'A promoção será excluída permanentemente.',
    DELETE_MODAL_CANCEL: 'Voltar',
    DELETE_MODAL_CONFIRM: 'Excluir',
    DELETE_BUTTON_LABEL: 'Excluir promoção',
  },
  LoadingModal: {
    LOADING_TITLE: 'Carregando...',
  },
  DDCSection: {
    DDC_TITLE: 'Centros de distribuição',
    DDC_DESCRIPTION: 'Adicione DCs elegíveis nos quais esta promoção estará disponível.',
    ADD_DDC: 'Adicionar CDs',
    ADD_MORE_DDC: 'Adicione CDs nessa lista',
    ID: 'ID',
    NAME: 'Nome',
    STATUS: 'Status',
    STATE: 'Estado',
    CITY: 'Cidade',
    ACTIVE: 'Ativo',
    INACTIVE: 'Inativo',
    NO_AVAILABLE_DDC: 'Nenhum DC disponível',
    NO_SELECTED_DDC: 'Nenhum DC elegível até agora. Comece a adicioná-los.',
    CONFIRM: 'Add DCs',
    CANCEL: 'Cancelar',
    SINGLE_DDC_SELECTED: 'DC selecionado',
    MULTIPLE_DDC_SELECTED: 'DCs selecionados',
  },
  PromotionSectionTable: {
    DESELECT_ALL: 'Remover seleção',
    ITEM_SELECTED: 'item selecionado',
    ITEMS_SELECTED: 'itens selecionados',
    PAGE_OF: 'de',
    SEARCH: 'Pesquisar',
  },
  InteractiveComboSection: {
    CREATE_INTERACTIVE_COMBO: 'Criar nova promoção',
    IMAGE_TITLE: 'Imagem',
    INFORMATION_TITLE: 'Informações',
    BUY_TITLE: 'Produtos necessários para desconto',
    BUY_DESCRIPTION:
      'Adicione produtos que o PDV precisa encomendar para obter produtos com desconto.',
    GET_TITLE: 'Produtos com desconto',
    GET_DESCRIPTION: 'Adicione os produtos com desconto que estarão disponíveis para o PDV.',
    DDC_TITLE: 'Centros de distribuição',
    DDC_DESCRIPTION: 'Adicione DCs elegíveis nos quais esta promoção estará disponível.',
    CANCEL_COMBO: 'Cancelar',
    CREATE_COMBO: 'Criar promoção',
    CREATE_TOAST_SUCCESS: 'Promoção criada com sucesso.',
    CREATE_TOAST_ERROR: 'Error creating interactive combo',
    UPDATE_TOAST_SUCCESS: 'Promoção atualizada com sucesso.',
    UPDATE_TOAST_ERROR: 'Error updating interactive combo',
    ALERT_DELETE_SUCCESS: 'Interactive combo successfully deleted.',
    ALERT_DELETE_ERROR: 'Algo deu errado. Tente novamente mais tarde.',
    DUPLICATED_TOAST_ERROR:
      'As listas de condições para os combos interativos não podem conter exatamente os mesmos produtos.',
  },
  BuySection: {
    MODAL_TITLE: 'Selecionar produtos',
    CANCEL: 'Cancelar',
    CONFIRM: 'Adicione produtos',
    QUANTITY_TOOLTIP:
      'A quantidade mínima definida será dividida entre os produtos selecionados de acordo com a preferência do PDV.',
    QUANTITY: 'Quantidade mínima necessária',
    ITEMS: 'Itens',
    NO_SELECTED_ITEMS: 'Nenhum produto necessário até agora. Comece a adicioná-los.',
    NO_AVAILABLE_ITEMS: 'Nenhum produto disponível',
    ADD_ITEMS: 'Adicione produtos',
    ADD_MORE_ITEMS: 'Adicione produtos nessa lista',
    PRODUCT: 'Produto',
  },
  GetSection: {
    MODAL_TITLE: 'Select products',
    CANCEL: 'Cancelar',
    CONFIRM: 'Adicione produtos',
    ITEMS: 'Items',
    NO_SELECTED_ITEMS: 'Nenhum produto com desconto ainda. Comece a adicioná-los.',
    NO_AVAILABLE_ITEMS: 'Nenhum produto disponível',
    ADD_ITEMS: 'Adicione produtos',
    ADD_MORE_ITEMS: 'Adicione produtos nessa lista',
    PRODUCT: 'Produto',
    PERCENTAGE: 'Porcentagem de desconto',
    MAX_QUANTITY: 'Quantidade máxima',
  },
  PromotionValidation: {
    UPDATE_START_DATE_ERROR:
      'Cannot edit a promotion that has already started. Change the startDate before editing.',
  },
  ImageCommon: {
    CAPTION: 'Format: JPG or PNG. Size: 1MB max.',
    SIZE_LIMIT_ERROR: 'Image size must be less than 1MB',
    UPDATE_ERROR: 'Error updating image',
  },
  InformationSectionCommon: {
    VENDOR_PROMOTION_ID: 'Vendor promotion ID',
    TITLE: 'Título',
    DESCRIPTION: 'Descrição',
    START_DATE: 'Data de início',
    END_DATE: 'Data de término',
    START_TIME: 'Hora de início',
    END_TIME: 'Hora de término',
    TITLE_PLACEHOLDER: 'Insira o título da promoção',
    DESCRIPTION_PLACEHOLDER: 'Insira a descrição da promoção',
    DATE_TIME_PLACEHOLDER: 'Selecionar',
    REQUIRED_FIELD: 'Required',
    GREATER_THAN_TODAY: 'Start date must be greater than today',
    GREATER_THAN_START_DATE: 'End date must be greater than start date',
  },
  PromotionDeleteItems: {
    TITLE: 'Delete this list of products?',
    DESCRIPTION: 'This list of products will be deleted permanently.',
    CANCEL: 'Go back',
    CONFIRM: 'Delete',
  },
  test: {
    translate: 'Traduzir',
    missing: 'Apenas opções inglês e português disponíveis',
    thisIsThePage: 'ESTA É A PÁGINA',
  },
};

export default ptBR;
