import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { Grid } from '@hexa-ui/components';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import PercentIcon from '@mui/icons-material/Percent';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import { Tag } from '@hexa-ui/icons';
import {
  setAppHeaderConfig,
  useAppHeader,
  useFeatureToggleV2,
  useSidebar,
  useUserMetadata,
} from 'admin-portal-shared-services';
import {
  projectName,
  promotionCountryEnabledFlag,
  promotionsBulkEditorFlag,
  promotionsListPageFlag,
} from '../constants/featureToggle';
import useFeatureToggleWithAttributes from '../hooks/useFeatureToggleWithAttributes';

export interface AppbarConfigProps {
  children: React.ReactNode;
}

/* istanbul ignore next */
const BulkEditorMenuIcon = () => <PriceCheckIcon />;
/* istanbul ignore next */
const ViewPricesMenuIcon = () => <ManageSearchOutlinedIcon />;
/* istanbul ignore next */
const BulkPromotionsMenuIcon = () => <PercentIcon />;
/* istanbul ignore next */
const ViewPromotionsMenuIcon = () => <Tag />;

export const AppbarConfig = ({ children }: AppbarConfigProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const [featureToggleStatus, isLoading] = useFeatureToggleV2(
    promotionsBulkEditorFlag,
    projectName
  );

  const [isUpdated, setIsUpdated] = useState(false);

  const { data, isLoading: isLoadingUserMetadata } = useUserMetadata();

  const [{ selectedCountry }] = useAppHeader();

  const attributes = useMemo(
    () => ({
      country: selectedCountry,
    }),
    [selectedCountry]
  );

  const [isPromotionEnabledCountry] = useFeatureToggleWithAttributes(
    promotionCountryEnabledFlag,
    attributes
  );

  const [isPromotionPageEnabled] = useFeatureToggleV2(promotionsListPageFlag, projectName);

  useEffect(() => {
    if (!isLoadingUserMetadata && data && !isUpdated) {
      setAppHeaderConfig({
        countryOptions: data?.supportedCountries as unknown as string[],
      });
      setIsUpdated(true);
    }
  }, [data, isUpdated, setIsUpdated, isLoadingUserMetadata]);

  const items = [
    {
      id: 'view_prices',
      title: formatMessage({ id: 'PageTitles.VIEW_PRICES' }),
      icon: ViewPricesMenuIcon,
      path: '/price-admin/view-prices',
    },
    {
      id: 'bulk_editor',
      title: formatMessage({ id: 'PageTitles.BULK_EDITOR' }),
      icon: BulkEditorMenuIcon,
      path: '/price-admin/price-bulk-editor',
    },
  ];

  if (isPromotionPageEnabled && isPromotionEnabledCountry) {
    items.push({
      id: 'view_promotions',
      title: formatMessage({ id: 'PageTitles.PROMOTIONS' }),
      icon: ViewPromotionsMenuIcon,
      path: '/price-admin/promotions',
    });
  }

  if (!isLoading && featureToggleStatus && selectedCountry && isPromotionEnabledCountry) {
    items.push({
      id: 'promotion_bulk_editor',
      title: formatMessage({ id: 'PageTitles.PROMOTION_BULK_EDITOR' }),
      icon: BulkPromotionsMenuIcon,
      path: '/price-admin/promotion-bulk-editor',
    });
  }

  useSidebar({
    items,
    utils: [],
  });

  return (
    <Grid.Container
      style={{
        rowGap: '1rem',
      }}
      type="fluid"
      sidebar
    >
      {children}
    </Grid.Container>
  );
};
