import { useUserMetadata } from 'admin-portal-shared-services';
import { ReactNode } from 'react';
import { IntlProvider as NativeProvider } from 'react-intl';
import enUS from './languages/en-US';
import es419 from './languages/es-419';
import ptBR from './languages/pt-BR';

const DEFAULT_LANGUAGE = 'en-US';
type IIntlProvider = {
  children: ReactNode;
};

export type MessageMap = {
  [key: string]: { [key: string]: string | MessageMap } | string;
};

export const messages: { [language: string]: MessageMap } = {
  'en-US': enUS,
  'pt-BR': ptBR,
  'es-419': es419,
};

export function IntlProvider({ children }: IIntlProvider): JSX.Element {
  const { data } = useUserMetadata();

  const hour12 = data?.profile?.preferredTimeFormat === 'h:mm a' ? true : false;

  let locale = DEFAULT_LANGUAGE;

  if (data?.profile?.preferredLanguage) {
    locale = data.profile.preferredLanguage;
  }

  const mergedMessages = mergeMessages(messages, locale);

  return (
    <NativeProvider
      locale={locale}
      defaultLocale={DEFAULT_LANGUAGE}
      messages={mergedMessages}
      formats={{ time: { userTimeFormat: { hour12, hourCycle: 'h23' } } }}
    >
      {children}
    </NativeProvider>
  );
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const flattenObject = (ob: any): any => {
  const toReturn: { [key: string]: any } = {};

  for (const i in ob) {
    if (typeof ob[i] === 'object' && ob[i] !== null) {
      const flatObject = flattenObject(ob[i]);
      // eslint-disable-next-line guard-for-in
      for (const x in flatObject) {
        toReturn[`${i}.${x}`] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};

export const mergeMessages = (messagesInput: MessageMap, selectedLocale: string): any => {
  const defaultMessages = flattenObject(messagesInput[DEFAULT_LANGUAGE]);
  const localeMessages = flattenObject(messagesInput[selectedLocale]);
  return { ...defaultMessages, ...localeMessages };
};

export default IntlProvider;
