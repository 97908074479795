import MessageMap from '../i18n.d';

const enUS: MessageMap = {
  PageTitles: {
    BULK_EDITOR: 'Price Bulk Editor',
    VIEW_PRICES: 'View Prices',
    DDC_PRICE_DETAILS: 'Price details for DDC',
    PROMOTION_BULK_EDITOR: 'Promotion Bulk Editor',
    PROMOTIONS: 'Promotions',
    NEW_PROMOTION: 'Create new promotion',
    DETAILS: 'Details',
  },
  ModalLabels: {
    UPLOAD_FILE: 'Upload file',
    CANCEL: 'Cancel',
    FILE_SELECTION_DETAILS: 'Drag and drop the CSV file here or',
    FILE_SELECTION_BUTTON: 'Select file',
    SEE_DETAILS: 'See details',
    HIDE_DETAILS: 'Hide details',
  },
  CsvStatusMessages: {
    UPLOAD_SUCCESS: 'The file uploaded successfully',
    FILE_NOT_UPLOADED:
      "We couldn't upload the file. Please, wait a few minutes before you try again",
    ONLY_CSV_SUPPORT: 'We only support CSV files',
    INVALID_CSV_FILE: 'We found some issues in the file',
    EMPTY_CSV_FILE: 'The file is empty',
    REQUIRED_HEADER_CSV: 'The following columns are missing',
    DUPLICATED_HEADER_CSV: 'The following columns are duplicated',
    INVALID_HEADER_CSV: 'The following columns are not allowed',
    MIN_LINES_REQUIRED_CSV: 'The minimum number of lines is',
    MAX_LINES_EXCEDED_CSV: 'The maximum number of lines is',
    REQUIRED_FIELDS_CSV: 'The following lines are missing some data',
    INVALID_FIELD_TYPE_CSV: 'The following lines contain invalid data types',
    INVALID_FIELD_VALUE_CSV: 'The following lines contain invalid data values',
    CONDITIONAL_REQUIRED_FIELD_CSV: 'The following lines requires specific data',
    INVALID_DATE_FORMAT: 'The following lines contain invalid date format',
    DATE_SMALLER_THAN_REF: 'The following lines contain dates less than a reference date',
    INVALID_DATE_VALUE: 'The following lines requires specific date',
    FORMAT_MUST_BE: 'format must be',
    MUST_BE_GREATER_THAN: 'must be greater than',
    DEPENDS_ON: 'depends on',
    EXPECTED_NUMBER_FORMAT: 'A number is expected (ex: 12345.67)',
    CONDITIONAL_EXCLUDING_FIELD_CSV: 'The following lines contain mutually exclusive data',
    DOES_NOT_ALLOW_COLUMN: 'does not allow filling the column',
    SHOULD_CONTAIN_EXPECTED_VALUES: 'should contain one of the following',
    MUST_BE_EQUAL_OR_GREATER_THAN: 'must be greater than or equal to',
  },
  Table: {
    PAGE_COUNTER: 'of',
    SEARCH_PLACEHOLDER: 'Search',
    ROW_SELECTED: 'row selected',
    ROWS_SELECTED: 'rows selected',
    DOWNLOAD_BUTTON: 'DOWNLOAD',
    LOADING_MESSAGE: 'Loading items...',
    ROWS_DESELECT_ALL: 'Deselect all',
  },
  HistoryTable: {
    TITLE: 'History',
    NO_UPLOADS: 'No uploads',
    NO_UPLOADS_DESCRIPTION: 'The uploaded files will be displayed here',
    TEMPLATE_LINK_DESCRIPTION: 'Click here to download the template',
    FILE_TEMPLATE_NAME_PRICES: 'prices_template',
    FILE_TEMPLATE_NAME_PROMOTIONS: 'promotions_template',
    HEADER_DATE: 'Date',
    HEADER_USER_NAME: 'Username',
    HEADER_STATUS: 'Status',
    HEADER_FILE_ROWS_QTY: 'Rows',
    HEADER_FILE_LINK: 'Download',
    DATA_ERROR: 'Something went wrong while getting the upload history',
    STATUS_PASS: 'Success',
    STATUS_FAIL: 'Fail',
  },
  ViewPricesLabels: {
    DISTRIBUTION_CENTER: 'Distribution Center',
    SELECT_DDC: 'Select',
    DDC_ERROR: 'Unable to get list of DDC',
    NO_DDC_AVAILABLE: 'No DDC available',
    HEADER_PRODUCT_TITLE: 'Product Title',
    HEADER_DDC: 'DDC',
    HEADER_STATUS: 'Status',
    STATUS_ENABLE: 'Enabled',
    STATUS_DISABLE: 'Disabled',
    HEADER_BRAND: 'Brand',
    HEADER_MANUFACTURER: 'Manufacturer',
    HEADER_PRICE: 'Base price',
    HEADER_LAST_EDITED: 'Last Edited',
    HEADER_REGULAR_DISCOUNT: 'Regular Discount',
    HEADER_TIERED_DISCOUNT: 'Tiered Discount',
    NO_PRICES: 'No prices',
    NO_PRICES_DESCRIPTION: 'The prices will be displayed here',
    PRICES_ERROR: 'Something went wrong while getting the prices',
    FILTERS: {
      PRICES: 'Price',
      WITHOUT_PRICE: 'Products without price',
      WITH_PRICE: 'Products with price',
      STATUS: 'Status',
      ENABLED: 'Enabled',
      DISABLED: 'Disabled',
      REGULAR_DISCOUNT: 'Regular discount',
      TIERED_DISCOUNT: 'Tiered discount',
      WITH_DISCOUNT: 'Products with discount',
      WITHOUT_DISCOUNT: 'Products without discount',
    },
    DOWNLOAD_FILE_NAME: 'selected_prices',
    DOWNLOAD_SUCCEEDED: 'Pricing file has been successfully downloaded',
    DOWNLOAD_FAILED: 'Unable to get the pricing file',
  },
  FilterComponent: {
    CANCEL_BUTTON: 'Cancel',
    CONFIRM_BUTTON: 'Apply',
    LABEL: 'Filter',
    CLEAR_ALL: 'Clear all',
  },
  DDCPriceDetailsLabels: {
    PRICES_ERROR: 'Something went wrong while getting the price details',
    SKU: 'SKU',
    VENDOR_ITEM_ID: 'VendorItemId',
    CLASS: 'Class',
    CREATED_ON: 'Created On',
    ATTRIBUTES: 'Attributes',
    DELIVERY_CENTER: 'Delivery Center',
    BASE_PRICE: 'Base Price',
    MINIMUM_PRICE: 'Minimum Price',
    DEPOSIT: 'Deposit',
    TAXES: 'Taxes',
    TAX_ID: 'ID',
    TAX_TYPE: 'Type',
    TAX_VALUE: 'Value',
    PRODUCT_DETAILS_BOX_HEADER: 'Product Information',
    PRODUCT_TITLE: 'Product Title',
    PRODUCT_DESCRIPTION: 'Product Description',
    STATUS: 'Status',
    MANUFACTURER: 'Manufacturer',
    PRICE_TYPE: 'Price Type',
    MEASURE_UNIT: 'Measure Unit',
    REQUIRED_VALUE: 'Required!',
    REQUESTED_CHANGE:
      'Price change request was successful. Please, wait a few moments until the change is available.',
    REQUESTED_CHANGE_FAIL: 'Unable to send price change request!',
    MODAL_DELETE_PRICE_TITLE: 'Delete price',
    MODAL_DELETE_PRICE_DESCRIPTION:
      'This action will delete prices and related taxes. Do you want to proceed?',
    MODAL_DELETE_PRICE_CANCEL: 'Cancel',
    MODAL_DELETE_PRICE_CONFIRM: 'Delete',
    ADD_TAX: 'Add Tax',
    TAXES_EMPTY: 'The taxes will be shown here.',
    TAX_BASE_ID: 'Tax Id Based Inclusion',
    ORDER_MINIMUM_VALUE: 'Order Sub-total Minimum Value',
    VALID_FROM: 'Valid From',
    BASE_PRICE_ERROR_MESSAGE: 'Base Price must be greater than Minimum Price',
  },
  AlertMessages: {
    COUNTRY_NOT_ALLOWED: 'Country not allowed',
    PAGE_NOT_FOUND: 'Page not Found',
  },
  PriceAuditLogTable: {
    OLD: 'Old',
    NEW: 'New',
    EMPTY_TEXT: 'There are no actions to be displayed',
    ERROR_TEXT: 'An error occurred while trying to get the log information',
    LOADING_MESSAGE: 'Loading items',
    DATE: 'Date',
    USER: 'User Name',
    ACTION: 'Action',
    HEADER: 'Audit Information',
    ORIGIN: 'Origin',
    INTERFACE: 'Interface',
    CSV_UPLOAD: 'CSV Upload',
    DELETE: 'Delete',
    INSERT: 'Creation',
    UPDATE: 'Update',
    VIEW: 'Visualization',
  },
  TieredDiscountDialog: {
    TRIGGER: 'Show',
    TITLE: 'Tiered discount',
    DESCRIPTION: 'Description',
    BASE_PRICE: 'Base price',
    DISCOUNT_RANGE: 'Discount range',
    QUANTITY: 'Quantity',
    DISCOUNT: 'Discount',
    PERCENTAGE: 'Percentage',
    DISCOUNT_PRICE: 'Discount price',
    ROUNDED_VALUES_TOOLTIP:
      'The numbers displayed might have been rounded for display purpose, please view the price details for more information',
  },
  DiscountSection: {
    REGULAR_DISCOUNT: 'Regular Discount',
    TIERED_DISCOUNT: 'Tiered Discount',
    IMAGE: 'Image',
    TITLE: 'Title',
    DESCRIPTION: 'Description',
    DISCOUNT: 'Amount',
    PERCENTAGE: 'Percentage',
    FINAL_PRICE: 'Price after discount',
    START_DATE: 'Start date',
    END_DATE: 'End date',
    VENDOR_PROMOTION_ID: 'Vendor promotion ID',
    QUANTITY: 'Product quantity',
    TYPE: 'Type',
    START_TIME: 'Start time',
    END_TIME: 'End time',
    FINAL_PRICE_TOOLTIP: 'This final price contains only the discount amount applied.',
    ACCUMULATION: 'Accumulation type',
    PRIORITY: 'Priority',
    MINIMUM: 'From',
    MAXIMUM: 'To',
    CREATE: 'Create discount',
    DISCARD: 'Discard',
    VENDOR_PROMOTION_ID_DESCRIPTION: 'Letters, numbers, and special characters allowed.',
    AMOUNT_OFF: 'Amount',
    PERCENT_OFF: 'Percent',
    PROMOTIONAL_PRICE: 'Price after discount',
    IMAGE_CAPTION: 'Images must be JPG or PNG, and less than 1MB.',
    ADD_FIELD: 'Add field',
    DISCOUNTS_EMPTY: 'The discounts will be shown here.',
    PAGE_COUNTER: 'of',
    ADD_DISCOUNT: 'Create discount',
    NOTIFICATION_SUCCESS_CREATED: 'Discount successfully created!',
    NOTIFICATION_ERROR_CREATED: 'Unable to create discount',
    NOTIFICATION_SUCCESS_EDITED: 'Discount successfully updated!',
    NOTIFICATION_ERROR_EDITED: 'Unable to edit discount',
    NOTIFICATION_SUCCESS_DELETED: 'Discount successfully deleted!',
    NOTIFICATION_ERROR_DELETED: 'Unable to remove discount',
    DELETE_TITLE: 'Delete this discount?',
    DELETE_QUESTION: 'It will be deleted permanently.',
    DELETE_CANCEL: 'No, go back',
    DELETE_CONFIRM: 'Yes, delete',
    START_DATE_HIGHER_THAN_TODAY: 'The start date cannot be less than the current day',
    FIELD_IS_REQUIRED: 'Required',
    PLACEHOLDER_VENDOR_PROMOTION_ID: 'Enter a promotion ID',
    PLACEHOLDER_TITLE: 'Enter a discount title',
    PLACEHOLDER_DESCRIPTION: 'Enter a discount description',
    MIN_FINAL_PRICE: 'The price after discount cannot be less than the minimum price',
    MAX_START_DATE: 'The start date cannot be greater than the end date',
    ADD: 'Add',
    COMPOSE: 'Compose',
    UNIQUE: 'Unique',
    HIGH: 'High',
    LOW: 'Low',
    MIN_QUANTITY_ERROR: 'Must be one unit greater than the last maximum quantity',
    MAX_QUANTITY_ERROR: 'Must be greater than minimum quantity',
    INVALID_CHARACTER_ERROR: 'Invalid character detected',
  },
  TimePicker: {
    SAVE: 'Save',
  },
  PromotionsListPage: {
    Table: {
      PROMO_ID: 'Promotion ID',
      TITLE: 'Title',
      DESCRIPTION: 'Description',
      DDC: 'DC',
      START_DATE: 'Start date',
      END_DATE: 'End date',
      TYPE: 'Type',
      STATUS: 'Status',
      EMPTY_STATE_MESSAGE: 'Select the DCs to manage promotions.',
    },
    FREE_GOODS: 'Free Good',
    INTERACTIVE_COMBO: 'Interactive Combo',
    DIGITAL_TRADE: 'Digital Trade',
    FIXED_COMBO: 'Fixed Combo',
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    ENABLED: 'Enabled',
    DISABLED: 'Disabled',
    SCHEDULED: 'Scheduled',
    SELECT_DDC: 'Select a DDC',
    MULTIPLE_DDC_SELECTED: 'DDCs selected',
    DDC_ERROR: 'Unable to get DDCs',
    DDC_LOADING: 'Loading DDCs',
    DDC_LABEL: 'Distribution Center',
    NEW_PROMOTION: 'Create new promotion',
    Filter: {
      TYPE: 'Type',
      STATUS: 'Status',
      DATE: 'Date',
      START_DATE: 'Start date',
      END_DATE: 'End date',
    },
  },
  PromotionTypeSection: {
    TITLE: 'Promotion type',
    FREE_GOOD_TITLE: 'Free good',
    FREE_GOOD_DESCRIPTION:
      'Create a condition within predefined products to enable free products for the POC.',
    INTERACTIVE_COMBO_TITLE: 'Interactive combo',
    INTERACTIVE_COMBO_DESCRIPTION:
      'Create a bundle of products with variable quantities and grant discounts for the purchase.',
    DIGITAL_TRADE_TITLE: 'Digital trade combo (coming soon)',
    DIGITAL_TRADE_DESCRIPTION:
      'Create a bundle of products that can be redeemed with points in the rewards program.',
    FIXED_COMBO_TITLE: 'Fixed combo (coming soon)',
    FIXED_COMBO_DESCRIPTION:
      'Create a bundle of products with fixed quantities and grant discounts for the purchase.',
    CONTINUE: 'Continue',
  },
  FreeGoodsPage: {
    CREATE_FREE_GOOD: 'Creating new free good',
    IMAGE: 'Image',
    IMAGE_SIZE_ERROR: 'Image size must be less than 1MB',
    IMAGE_UPDATE_ERROR: 'Error updating image',
    IMAGE_CAPTION: 'Images must be JPG or PNG, and less than 1MB.',
    INFORMATION: 'Information',
    BUY: 'Products that POCs need to order',
    GET: 'Free goods that POCs will get',
    DISTRIBUTION_CENTER: 'Distribution centers',
    NO_DISTRIBUTION_CENTER_MESSAGE:
      'The distribution centers selected to this promotion will appear here.',
    NO_ITEMS_MESSAGE: 'The selected products will appear here.',
    NO_FREE_GOODS_MESSAGE: 'The selected free goods will appear here.',
    ADD_BUY_ITEM: 'Add condition',
    ADD_GET_ITEM: 'Add free goods',
    ADD_DISTRIBUTION_CENTER: 'Add DCs',
    ADD_MORE_DDC: 'Add DCs to this list',
    TABLE_DESELECT_ALL: 'Deselect all',
    TABLE_ITEM_SELECTED: 'item selected',
    TABLE_ITEMS_SELECTED: 'items selected',
    TABLE_PAGE_OF: 'of',
    TABLE_SEARCH: 'Search',
    TABLE_NO_DDC_FOUND: 'No DDCs found',
    TABLE_NO_PRODUCT_FOUND: 'No products found',
    TABLE_CANCEL: 'Cancel',
    TABLE_CONFIRM_DDC: 'Add DCs',
    ID: 'ID',
    NAME: 'Name',
    STATUS: 'Status',
    STATE: 'Status',
    CITY: 'City',
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    CANCEL_FREE_GOOD: 'Cancel',
    SAVE_FREE_GOOD: 'Create free good',
    FREE_GOOD_CREATED: 'Free good successfully created.',
    FREE_GOOD_UPDATED: 'Free good successfully updated.',
    FREE_GOOD_ERROR: 'Unable to create free good',
    FREE_GOOD_UPDATED_ERROR: 'Unable to update free good',
    FREE_GOOD_UPDATE_START_DATE_ERROR:
      'Cannot edit a free good that has already started. Change the startDate before editing.',
    MINIMUM_QUANTITY: 'Minimum quantity must be',
    ONE_PRODUCT: 'Required for at least one product',
    DIVIDED_PRODUCTS: 'Divided among the products',
    ADD_PRODUCTS: 'Add products',
    PRODUCT: 'Product',
    CANCEL: 'Cancel',
    MINIMUM_QUANTITY_REQUIRED: 'Minimum quantity required',
    SELECT_ITEMS: 'Select products',
    SELECT_FREE_GOODS: 'Select free goods',
    ONCE_PER_ORDER: 'Once per order',
    EVERY_TIME_MINIMUM: 'Every time the minimum product quantity is met',
    TOTAL_QUANTITY_TOOLTIP:
      'The total quantity will be distributed among the products during the purchase.',
    FREE_GOOD_QUANTITY: 'Free good quantity',
    CONFIRM_ADD_ITEMS: 'Add free goods',
    ADD_MORE_ITEMS_TO_LIST: 'Add products to this list',
    FREE_GOOD_PROPORTION: 'When POCs can get free goods',
    ALERT_DELETE_SUCCESS: 'Free good successfully deleted.',
    ALERT_DELETE_ERROR: 'Something went wrong. Please try again later.',
    ITEMS: 'Items',
    DUPLICATED_GET_ERROR: 'The lists of free goods should not contain the exactly same products!',
  },
  DeletePromotionModal: {
    DELETE_MODAL_TITLE: 'Delete this promotion?',
    DELETE_MODAL_DESCRIPTION: 'The promotion will be deleted permanently.',
    DELETE_MODAL_CANCEL: 'Go back',
    DELETE_MODAL_CONFIRM: 'Delete',
    DELETE_BUTTON_LABEL: 'Delete promotion',
  },
  DDCSection: {
    DDC_TITLE: 'Distribution centers',
    DDC_DESCRIPTION: 'Add eligible DCs in which this promotion will be available.',
    ADD_DDC: 'Add DCs',
    ADD_MORE_DDC: 'Add DCs to this list',
    ID: 'ID',
    NAME: 'Name',
    STATUS: 'Status',
    STATE: 'Status',
    CITY: 'City',
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    NO_AVAILABLE_DDC: 'No DCs found',
    NO_SELECTED_DDC: 'No eligible DCs yet. Start adding them.',
    CONFIRM: 'Add DCs',
    CANCEL: 'Cancel',
    SINGLE_DDC_SELECTED: 'DC selected',
    MULTIPLE_DDC_SELECTED: 'DCs selected',
  },
  PromotionSectionTable: {
    DESELECT_ALL: 'Deselect all',
    ITEM_SELECTED: 'item selected',
    ITEMS_SELECTED: 'items selected',
    PAGE_OF: 'of',
    SEARCH: 'Search',
  },
  InteractiveComboSection: {
    CREATE_INTERACTIVE_COMBO: 'Create new promotion',
    IMAGE_TITLE: 'Image',
    INFORMATION_TITLE: 'Information',
    BUY_TITLE: 'Products required for discount',
    BUY_DESCRIPTION: 'Add products that the POC needs to order to get discounted products.',
    GET_TITLE: 'Discounted products',
    GET_DESCRIPTION: 'Add discounted products that will be available for the POC.',
    DDC_TITLE: 'Distribution centers',
    DDC_DESCRIPTION: 'Add eligible DCs in which this promotion will be available.',
    CANCEL_COMBO: 'Cancel',
    CREATE_COMBO: 'Create promotion',
    CREATE_TOAST_SUCCESS: 'Promotion successfully created.',
    CREATE_TOAST_ERROR: 'Error creating interactive combo',
    UPDATE_TOAST_SUCCESS: 'Promotion successfully updated.',
    UPDATE_TOAST_ERROR: 'Error updating interactive combo',
    ALERT_DELETE_SUCCESS: 'Interactive combo successfully deleted.',
    ALERT_DELETE_ERROR: 'Something went wrong. Please try again later.',
    GREATER_THAN_ZERO: 'Must be greater than zero',
    DUPLICATED_TOAST_ERROR:
      'The lists of interactive combos conditions should not contain exactly the same products.',
  },
  BuySection: {
    MODAL_TITLE: 'Select products',
    CANCEL: 'Cancel',
    CONFIRM: 'Add products',
    QUANTITY_TOOLTIP:
      'The minimum quantity set will be divided among the selected products according to the POCs preference.',
    QUANTITY: 'Minimum quantity required',
    ITEMS: 'Items',
    NO_SELECTED_ITEMS: 'No required products yet. Start adding them.',
    NO_AVAILABLE_ITEMS: 'No products found',
    ADD_ITEMS: 'Add Products',
    ADD_MORE_ITEMS: 'Add products to this list',
    PRODUCT: 'Product',
  },
  GetSection: {
    MODAL_TITLE: 'Select products',
    CANCEL: 'Cancel',
    CONFIRM: 'Add products',
    ITEMS: 'Items',
    NO_SELECTED_ITEMS: 'No discounted products yet. Start adding them.',
    NO_AVAILABLE_ITEMS: 'No products found',
    ADD_ITEMS: 'Add Products',
    ADD_MORE_ITEMS: 'Add products to this list',
    PRODUCT: 'Product',
    PERCENTAGE: 'Discount percentage',
    MAX_QUANTITY: 'Maximum quantity',
  },
  PromotionValidation: {
    UPDATE_START_DATE_ERROR:
      'Cannot edit a promotion that has already started. Change the startDate before editing.',
  },
  LoadingModal: {
    LOADING_TITLE: 'Loading...',
  },
  ImageCommon: {
    CAPTION: 'Format: JPG or PNG. Size: 1MB max.',
    SIZE_LIMIT_ERROR: 'Image size must be less than 1MB',
    UPDATE_ERROR: 'Error updating image',
  },
  InformationSectionCommon: {
    VENDOR_PROMOTION_ID: 'Vendor promotion ID',
    TITLE: 'Title',
    DESCRIPTION: 'Description',
    START_DATE: 'Start date',
    END_DATE: 'End date',
    START_TIME: 'Start time',
    END_TIME: 'End time',
    TITLE_PLACEHOLDER: 'Enter the promotion title',
    DESCRIPTION_PLACEHOLDER: 'Enter the promotion description',
    DATE_TIME_PLACEHOLDER: 'Select',
    REQUIRED_FIELD: 'Required',
    GREATER_THAN_TODAY: 'Start date must be greater than or equal to today.',
    GREATER_THAN_START_DATE: 'End date must be greater than start date',
  },
  PromotionDeleteItems: {
    TITLE: 'Delete this list of products?',
    DESCRIPTION: 'This list of products will be deleted permanently.',
    CANCEL: 'Go back',
    CONFIRM: 'Delete',
  },
  test: {
    translate: 'Translate',
    missing: 'Only English option available',
    thisIsThePage: 'THIS IS THE PAGE',
  },
};

export default enUS;
